.errorWrapper {
  position: relative;
  z-index: 1;
  padding: 50px 0;
  text-align: center;

  .cBtn {
    margin-top: 60px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
