@import url('https://fonts.googleapis.com/css?family=Avenir:300,400,500,700,900&display=swap');
@import "./mixin";

//font-family: 'Rubik', sans-serif;

/* Your default CSS. */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

body {
  background: #5e731033;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 1.5px;
  color: #444;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  font-family:  "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;

  .container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 85vw !important;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    @media (max-width: 1350px) {
      margin: 0 auto;
      width: 100% !important;
      padding: 0;
      overflow: hidden;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button,
  input,
  textarea {
    letter-spacing: 1px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: 700;
    color: #000;
    letter-spacing: 0;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    font-weight: inherit;
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    letter-spacing: 0;
  }

  h1 {
    font-size: 36px;
    line-height: 70px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
  }

  h4 {
    font-size: 20px;
    line-height: 27px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
  }

  p {
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
  }

  p img {
    margin: 0;
  }

  span {
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  }


  /* em and strong */

  small {
    font-size: 11px;
    line-height: inherit;
  }

  /* links */

  a,
  a:visited {
    text-decoration: none;
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: normal;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: 0;
  }

  a:hover,
  a:active {
    text-decoration: none;
    outline: 0;
  }

  a:focus {
    text-decoration: none;
    outline: 0;
  }

  p a,
  p a:visited {
    line-height: inherit;
    outline: 0;
  }


  /* list */

  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }

  ol {
    list-style: decimal;
  }

  ol,
  ul.square,
  ul.circle,
  ul.disc {
    margin-left: 0;
  }

  ul.square {
    list-style: square outside;
  }

  ul.circle {
    list-style: circle outside;
  }

  ul.disc {
    list-style: disc outside;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin: 0;
  }

  ul ul li,
  ul ol li,
  ol ol li,
  ol ul li {
    margin-bottom: 0;
  }

  li {
    line-height: 18px;
    margin-bottom: 0;
  }

  /* definition list */

  dl {
    margin: 12px 0;
  }

  dt {
    margin: 0;
    color: #11ABB0;
  }

  dd {
    margin: 0 0 0 20px;
  }

  //button
  button {
    cursor: pointer;
    outline: none;
  }

  .cBtn {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    height: 53px;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid $secondary_color;
    padding: 0 38px;
    line-height: 51px;
    text-transform: capitalize;
    letter-spacing: 0;
    background: $theme_gradiant;
    font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    @media (max-width: 767px) {
      font-size: 16px;
    }

    &:hover {
      a {
        color: $secondary_color;
      }
    }

    a {
      display: block;
      color: #ffffff;
    }

    span {
      font-family: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    }

    &:not(.disabledHover):hover {
      background: #ffffff;
      color: $secondary_color;
    }

    &.cBtnRadius {
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
    }

    &.cBtnOutline {
      background: transparent;
      color: $secondary_color;

      &:not(.disabledHover):hover {
        background: $theme_color;
        color: #ffffff;
      }
    }

    &.cBtnRed {
      background: linear-gradient(45deg, #ff584e, #ff7a72);
      border: 1px solid #0DC181;

      &:not(.disabledHover):hover {
        background: transparent;
        color: #ff584e;
      }
    }

    &.cBtnShadow {
      -webkit-box-shadow: 0 14px 26px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 14px 26px rgba(0, 0, 0, 0.16);
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.16);
    }

    //cBtnUppercase
    &.cBtnUppercase {
      text-transform: uppercase;
      font-size: 15px;
      line-height: 44px;
    }

    &.cBtnGradiantPurple {
      background: linear-gradient(45deg, #a126f9 0%, #dd56f9 100%);

      &:hover {
        -webkit-box-shadow: 0 9px 24px rgba(211, 78, 249, 0.4);
        -moz-box-shadow: 0 9px 24px rgba(211, 78, 249, 0.4);
        box-shadow: 0 9px 24px rgba(211, 78, 249, 0.4);
        color: #ffffff;
      }
    }

    //cBtnSmall
    &.cBtnSmall {
      height: 40px;
      line-height: 40px;
      min-width: unset;
      padding: 0 15px;
      font-size: 15px;
    }

    //cBtnFull
    &.cBtnFull {
      width: 100%;
    }
  }

  //classes
  .textCenter {
    text-align: center;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-10{
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-30 {
    margin: 30px;
  }

  .pl-50 {
    padding-left: 50px;
  }

  .pr-50 {
    padding-right: 50px;
  }

  .pl-40 {
    padding-left: 40px;
  }

  .pl-20 {
    padding-left: 20px;
  }

  .pr-40 {
    padding-right: 40px;
  }

  .pr-20 {
    padding-right: 20px;
  }

  .pt-50 {
    padding-top: 50px;
  }

  .pt-100 {
    padding-top: 100px;
  }

  .pb-120 {
    padding-bottom: 120px;
  }

  .ptb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .xlg-pt-50 {
    @media (max-width: 1350px) and (min-width: 992px) {
      padding-top: 50px;
    }
  }

  .mtb-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .p0 {
    padding: 0 !important;
  }

  .ml-80 {
    margin-left: 80px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .text-right {
    text-align: right;
  }

  @media (max-width: 991px) {
    .justify-md-flex-space-between {
      justify-content: space-between;
    }
  }
  @media (max-width: 767px) {
    .direction-sm-column-reverse {
      flex-direction: column-reverse;
    }
  }

  .Toastify__toast-body {
    letter-spacing: 0;
  }


  .inputStyleBasic {
    width: 100%;

    > div {
      padding: 0;

      &.Mui-focused {
        fieldset {
          border-color: $theme_color !important;
          border-width: 1px !important;
        }
      }

      fieldset {
        border-color: #CCD0D7;
        border-width: 1px;
      }

      textarea,
      input {
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 25px;
        font-size: 16px;
        letter-spacing: 0;

        &::-webkit-input-placeholder {
          color: #8B8B8C;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #8B8B8C;
          opacity: 1;
        }

        &::placeholder {
          color: #8B8B8C;
          opacity: 1;
        }
      }

      textarea {
        height: 100px !important;
        padding: 18.5px 14px;
        padding-top: 12px;
        padding-left: 25px;
        line-height: 25px;
      }
    }

    p {
      font-size: 12px;
      color: #d54d4d;
      text-transform: capitalize;
    }
  }

}
